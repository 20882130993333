import { ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UserData } from 'src/server/types'
import { login } from 'src/store/reducers/user.client'
import { useAppDispatch } from 'universal-scripts'

const base = process.env.APP_URL || 'http://localhost:3000'

type Status = 'idle' | 'error' | 'loading' | 'success'

export default function OAuth() {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState<Status>('idle')

  const code = searchParams.get('code')

  useEffect(() => {
    if (!code) setStatus('error')
    setStatus('loading')

    const authUser = async () => {
      const response = await fetch(`${base}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code })
      })
      const data = (await response.json()) as UserData
      return data
    }

    authUser()
      .then((data) => {
        setStatus('success')
        dispatch(login(data))
      })
      .catch(() => setStatus('error'))
  }, [code, dispatch])

  const statusRecord: Record<Status, ReactNode> = {
    error: 'Error',
    idle: 'Idle',
    loading: 'Loading...',
    success: 'Completed'
  }

  return <main id="oauth">{statusRecord[status]}</main>
}
