import Pill from '../Pill/Pill'
import { useIntl } from 'react-intl'
import { useKpisWithPendingData } from '@hooks/useApi'
import timeUtils from '@utils/time'
import { useMetrics } from '@hooks/useMetrics'
import { useAppSelector } from 'universal-scripts'

interface WeeksProps {
  middle: number
  radius: number
  msPerPx: number
  changeCenter: (ms: null) => void
}

export default function Weeks({
  msPerPx,
  radius,
  middle,
  changeCenter
}: WeeksProps) {
  const { center } = useMetrics()
  const week = timeUtils.getWeekByMs(center)
  const actualWeek = week

  const actual = timeUtils.getMondayAndReset(new Date())

  const selectedWarehouse = useAppSelector((s) => s.warehouse)

  const intl = useIntl()
  const pendingKpis = useKpisWithPendingData()

  const weeks = timeUtils.getWeeksRange(
    middle - radius * 2,
    middle + radius * 2
  )

  const weeklyPendingKpis = pendingKpis.filter((pending) => {
    return (
      pending.kpiFrequency === 'weekly' &&
      (selectedWarehouse === pending.warehouse?.toLowerCase() ||
        pending.warehouse === null)
    )
  })

  const pendingWeeks = weeklyPendingKpis
    .map(({ missing_slots }) => missing_slots)
    .flat()

  return weeks.map((week) => {
    const weekNumber = timeUtils.getWeekByMs(week)

    const isActive =
      actualWeek === weekNumber &&
      new Date(week).getUTCFullYear() === new Date(center).getUTCFullYear()
    const translate = (week - middle) / msPerPx

    const isActual = week === actual.getTime()

    return (
      <Pill
        ms={week}
        translate={translate}
        label={intl.formatMessage(
          { id: 'pills.weeks.label' },
          { value: weekNumber }
        )}
        isActual={isActual}
        key={week}
        isFuture={week > actual.getTime()}
        active={isActive}
        hasPending={pendingWeeks.includes(
          `${new Date(week).getUTCFullYear()}-W${weekNumber.toString().padStart(2, '0')}`
        )}
        changeCenter={changeCenter}
      />
    )
  })
}
