import { Helmet } from 'react-helmet-async'

export default function Meta() {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <title>Fundilusa Hub</title>
      <link
        rel="icon"
        type="image/x-icon"
        sizes="16x16"
        href="/favicon-16.ico"
      />
      <link
        rel="icon"
        type="image/x-icon"
        sizes="32x32"
        href="/favicon-32.ico"
      />
      <link rel="icon" type="image/png" sizes="48x48" href="/favicon-48.png" />
      <link rel="icon" type="image/png" sizes="64x64" href="/favicon-64.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/favicon-512.png"
      />

      <link rel="apple-touch-icon" sizes="128x128" href="/favicon-128.png" />
      <link rel="apple-touch-icon" sizes="512x512" href="/favicon-512.png" />

      <meta name="msapplication-TileImage" content="/favicon-128x128.png" />
      <meta name="msapplication-TileColor" content="#ffffff" />

      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#161617" />
      {/* <script>
        {
          'if ("serviceWorker" in navigator) navigator.serviceWorker.register("/sw.js")'
        }
      </script> */}
      <script>
        {`if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
                .then(function(registrations) {
                    for(let registration of registrations) {
                      registration.unregister();
                    }
                });
        }`}
      </script>
    </Helmet>
  )
}
