import { Link } from 'react-router-dom'

import './GlobalError.css'
import Common from '@components/Header/Menu/Common'
import { FormattedMessage } from '@components/Intl/FormattedMessage'

interface GlobalErrorProps {
  error: Error | null
}

export function GlobalError({ error }: GlobalErrorProps) {
  return (
    <section id="global-error">
      <Common />
      <div>
        <h1>
          <FormattedMessage id="error.global.title" />
        </h1>
        <p>
          <FormattedMessage id="error.global.subtitle" />
        </p>
      </div>
      <p>{error.message}</p>
      <Link to="/" replace>
        <FormattedMessage id="error.global.go-back" />
      </Link>
    </section>
  )
}
