import timeUtils from '@utils/time'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState
} from 'react'
import { Kpi } from 'src/server/types'
import type { Frequency } from 'src/types'

const SHOWED_ITEMS = 3

type PendingMetrics = Record<number, Omit<Kpi, 'id'>>

interface MetricsContextValues {
  addPendingMetric: (key: number, value: Omit<Kpi, 'id'>) => void
  resetPendingMetrics: () => void
  changeFreq: () => void
  changeFreqScroll: (freq: Frequency) => void
  changeCenter: Dispatch<SetStateAction<number>>
  pendingMetrics: PendingMetrics
  selectedFreq: Frequency
  center: number
}

export const MetricsContext = createContext<MetricsContextValues>({
  addPendingMetric: () => null,
  resetPendingMetrics: () => null,
  changeFreq: () => null,
  changeFreqScroll: () => null,
  changeCenter: () => null,
  selectedFreq: 'weekly',
  pendingMetrics: [],
  center: timeUtils.initializeRangeWeeks(
    SHOWED_ITEMS,
    timeUtils.getPreviusWeekDate()
  )[0]
})

interface MetricsContextProviderProps {
  children: ReactNode
}

export default function MetricsContextProvider({
  children
}: MetricsContextProviderProps) {
  const [pendingMetrics, setPendingMetrics] = useState<PendingMetrics>({})
  const [selectedFreq, setSelectedFreq] = useState<Frequency>('weekly')

  const [initialCenter] = timeUtils.initializeRangeWeeks(
    SHOWED_ITEMS,
    timeUtils.getPreviusWeekDate()
  )

  const [center, changeCenter] = useState<number>(initialCenter)

  const changeFreq = useCallback(
    () =>
      setSelectedFreq((prev) => (prev === 'monthly' ? 'weekly' : 'monthly')),
    []
  )

  const changeFreqScroll = useCallback(
    (freq: Frequency) =>
      setSelectedFreq((prev) => {
        if (prev === freq) return prev
        return freq
      }),
    []
  )

  const addPendingMetric = useCallback(
    (key: number, value: Omit<Kpi, 'id'>) =>
      setPendingMetrics((prev) => ({ ...prev, [key]: value })),
    []
  )

  const resetPendingMetrics = useCallback(() => setPendingMetrics([]), [])

  return (
    <MetricsContext.Provider
      value={{
        addPendingMetric,
        resetPendingMetrics,
        changeFreq,
        changeFreqScroll,
        changeCenter,
        selectedFreq,
        pendingMetrics,
        center
      }}
    >
      {children}
    </MetricsContext.Provider>
  )
}
