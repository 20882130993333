import { useEffect, useState } from 'react'

export default function useHeader() {
  const [isAtTop, setIsAtTop] = useState<boolean>(true)

  useEffect(() => {
    const handleScroll = () => {
      const titleHeight = document.querySelector('h1').clientHeight
      const headerHeight = document.querySelector('header').clientHeight

      setIsAtTop(window.scrollY < titleHeight + headerHeight)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return isAtTop
}
