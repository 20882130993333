function throttle(func: (...args: unknown[]) => void, delay: number) {
  let lastCall = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCall < delay) return
    lastCall = now
    return func(...args)
  }
}

function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null
  let pendingArgs: Parameters<T> | null = null
  let isRunning = false

  const runFunction = (args: Parameters<T>) => {
    isRunning = true
    func(...args)
    isRunning = false

    if (pendingArgs) {
      timeout = setTimeout(() => {
        const argsToExecute = pendingArgs
        pendingArgs = null
        runFunction(argsToExecute!)
      }, wait)
    } else {
      timeout = null
    }
  }

  return (...args: Parameters<T>) => {
    if (!isRunning) {
      runFunction(args)
    } else {
      pendingArgs = args
      if (timeout) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          const argsToExecute = pendingArgs
          pendingArgs = null
          runFunction(argsToExecute!)
        }, wait)
      }
    }
  }
}

const utils = {
  throttle,
  debounce
}

export default utils
