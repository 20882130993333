import metricsUtils from '@utils/metrics'
import { useMemo } from 'react'
import { Overview } from 'src/server/types'
import { useFilters } from './useFilters'
import filtersUtils from '@utils/filters'
import timeUtils from '@utils/time'
import { type Factory, type KpiWithData } from 'src/types'
import chartsUtils from '@utils/charts'

export function useKpiWithData(kpis: Overview, warehouse: Factory) {
  const { selectedCategory, selectedSubcategories, showAll } = useFilters()

  const filteredKpisByWarehouse = useMemo(
    () => metricsUtils.filterByWarehouse(kpis, warehouse),
    [kpis, warehouse]
  )

  const filteredKpis = useMemo(
    () =>
      filtersUtils.applyFilters(
        {
          selectedCategory,
          selectedSubcategories,
          showAll
        },
        filteredKpisByWarehouse
      ),
    [selectedCategory, selectedSubcategories, showAll, filteredKpisByWarehouse]
  )

  const actualYear = new Date().getUTCFullYear()

  const actualSlotWeekly = `${actualYear}-W${timeUtils.getWeekNumber(new Date()).toString().padStart(2, '0')}`
  const actualSlotMonthly = `${actualYear}-Y${timeUtils.getMonthNumber(new Date()).toString().padStart(2, '0')}`

  const kpisWithData: KpiWithData[] = useMemo(
    () =>
      filteredKpis.map((kpi) => {
        const { kpiFrequency, history, target } = kpi
        const isWeekly = kpiFrequency === 'weekly'
        const actualSlot = isWeekly ? actualSlotWeekly : actualSlotMonthly

        const actual = history.find((history) => history.slot === actualSlot)
        const data = chartsUtils.mergeData(history, target, kpiFrequency)

        return { ...kpi, data: data.reverse(), actual: { ...actual } }
      }),
    [actualSlotMonthly, actualSlotWeekly, filteredKpis]
  )

  const sortedByWarehouse = useMemo(
    () =>
      structuredClone(kpisWithData).sort((itemA, itemB) => {
        if (itemA.warehouse === null && itemB.warehouse !== null) return -1
        if (itemA.warehouse !== null && itemB.warehouse === null) return 1
        return itemB.history.length - itemA.history.length
      }),
    [kpisWithData]
  )

  return sortedByWarehouse
}
