import BarChartContainer from '@components/ChartsContainers/BarChart/BarChartContainer'
import GuageContainer from '@components/ChartsContainers/Guage/GuageContainer'
import { Icons } from '@static/icons'
import { TouchEvent, useCallback, useEffect, useRef, useState } from 'react'
import chartsUtils, { MergedChartData } from '@utils/charts'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import metricsUtils from '@utils/metrics'

import type { Frequency } from 'src/types'

import './KpiChartRow.css'

const GAP = 8
const VELOCITY_THRESHOLD = 0.5

interface KpiChartRowProps {
  actual: {
    value: number
    target: number
  }
  id: number
  name: string
  data: MergedChartData[]
  frequency: Frequency

  allPinned: number[]
  updatePinnedKpi: (id: number) => void
}

export default function KpiChartRow({
  actual: { target, value },
  id,
  name,
  data,
  frequency,
  allPinned,
  updatePinnedKpi
}: KpiChartRowProps) {
  const [position, setPosition] = useState<number>(0)
  const [dragging, setDragging] = useState<boolean>(false)
  const [showed, setShowed] = useState<boolean>(false)

  const dragRef = useRef<HTMLDivElement>(null)
  const anchorRef = useRef<HTMLDivElement>()
  const elementPosition = useRef<number>(0)
  const startX = useRef<number>(0)
  const startY = useRef(0)
  const startTime = useRef<number>(0)

  const pinned = allPinned.includes(id)

  useEffect(() => {
    if (!dragRef.current) return

    const container = dragRef.current

    const callback: IntersectionObserverCallback = ([entry]) => {
      setShowed(entry.isIntersecting)
    }

    const observer = new IntersectionObserver(callback, {
      root: null
    })

    observer.observe(container)

    return () => {
      if (container) observer.unobserve(container)
    }
  }, [allPinned])

  const calculateOpacity = useCallback((deltaX: number) => {
    return chartsUtils
      .convertRange(deltaX, 0, anchorRef.current.offsetWidth, 0.4, 1)
      .toFixed(2)
  }, [])

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      const target = e.target as HTMLElement
      if (target.classList.contains('graph-item')) return

      setDragging(true)
      startX.current = e.touches[0].clientX
      startY.current = e.touches[0].clientY
      elementPosition.current = position
      startTime.current = Date.now()
    },
    [position]
  )

  const updatePositionWithinLimits = useCallback((newPosition: number) => {
    const maxDragLimit = dragRef.current.offsetWidth - window.innerWidth + GAP
    const anchorWidth = anchorRef.current.offsetWidth

    if (newPosition > anchorWidth) return anchorWidth

    if (Math.abs(newPosition) >= maxDragLimit) return maxDragLimit

    return newPosition
  }, [])

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!dragging) return

      const { clientX: currentX, clientY: currentY } = e.touches[0]

      const deltaX = currentX - startX.current
      const deltaY = currentY - startY.current

      if (Math.abs(deltaY) > Math.abs(deltaX)) return

      const newPosition = elementPosition.current + deltaX

      const timeElapsed = Date.now() - startTime.current
      const velocity = Math.abs(deltaX) / timeElapsed

      if (velocity > VELOCITY_THRESHOLD) {
        setDragging(false)
        if (deltaX > 0 && elementPosition.current !== 0) {
          setPosition(0)
        } else {
          setPosition(-1 * (320 + GAP))
        }

        return
      }

      const newPositionBetween = updatePositionWithinLimits(newPosition)

      const opacity =
        newPositionBetween >= 0 ? calculateOpacity(newPositionBetween) : '0'
      anchorRef.current!.style.opacity = opacity

      setPosition(newPositionBetween)
    },
    [updatePositionWithinLimits, dragging, calculateOpacity]
  )

  const handlePin = () => {
    if (pinned) {
      metricsUtils.removePinned(id)
    } else {
      metricsUtils.savePinned(id)
    }
    updatePinnedKpi(id)
  }

  const handleTouchEnd = () => {
    setDragging(false)

    const opacity = +anchorRef.current!.style.opacity
    const updatedPosition =
      position < -window.innerWidth / 2 + 48 ? -1 * (320 + GAP) : 0
    setPosition(updatedPosition)
    anchorRef.current!.style.opacity = '0'

    if (opacity === 1) {
      handlePin()
      setPosition(0)
    }
  }

  if (!showed) {
    return (
      <div
        ref={dragRef}
        className="kpi-entry"
        style={{ height: '224px', width: '100%' }}
      >
        <span style={{ opacity: '0' }}>{name}</span>
      </div>
    )
  }

  return (
    <div
      className="kpi-entry"
      ref={dragRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        ref={anchorRef}
        className="pin"
        role="button"
        aria-label="Pin element"
        onClick={handlePin}
      >
        <img
          src={
            pinned ? Icons.detachPushpinIcon.source : Icons.pushpinIcon.source
          }
          alt={pinned ? Icons.detachPushpinIcon.alt : Icons.pushpinIcon.alt}
        />
        <span>
          <FormattedMessage
            id={pinned ? 'overview.guage.detach-pin' : 'overview.guage.pin'}
          />
        </span>
      </div>

      <article
        style={{
          transform: `translateX(${position}px)`
        }}
        className={dragging ? 'drag' : ''}
      >
        <GuageContainer
          moved={position !== 0}
          target={target}
          value={value}
          name={name}
          pinned={pinned}
        />
        <BarChartContainer data={data} frequency={frequency} />
      </article>
    </div>
  )
}
