import warehouse from './slices/warehouse'
import userServer from './reducers/user.server'
import userClient from './reducers/user.client'

const user = __SERVER__ ? userServer : userClient

export const reducerList = {
  user,
  warehouse
}

export type ReducerType = {
  [Key in keyof typeof reducerList]: ReturnType<(typeof reducerList)[Key]>
}

export default reducerList
