import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage/SelectLanguage'
import { Icons } from '@static/icons'
import { useAppDispatch } from 'universal-scripts'
import { logout } from 'src/store/reducers/user.client'

import './Menu.css'

interface MenuProps {
  open: boolean
}

export default function Menu({ open }: MenuProps) {
  const dispatch = useAppDispatch()

  return (
    <div id="menu" className={open ? 'open' : 'closed'}>
      <menu>
        <li>
          <Link to="/">
            <FormattedMessage id="dashboard.title" />
          </Link>
        </li>
        <li>
          <Link to="/overview">
            <FormattedMessage id="overview.title" />
          </Link>
        </li>
        <li>
          <SelectLanguage />
        </li>
      </menu>
      <button onClick={() => dispatch(logout())}>
        <FormattedMessage id="menu.logout" />
        <img src={Icons.logoutIcon.source} alt={Icons.logoutIcon.alt} />
      </button>
    </div>
  )
}
