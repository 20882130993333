import { useFilters } from '@hooks/useFilters'
import { Icons } from '@static/icons'
import { useIntl } from 'react-intl'

interface ShowFiltersProps {
  openDialog: () => void
}

export default function ShowFilters({ openDialog }: ShowFiltersProps) {
  const intl = useIntl()
  const { selectedCategory, selectedSubcategories, showAll } = useFilters()

  const category = selectedCategory
    ? `${intl.formatMessage({ id: `overview.filter.${selectedCategory}.name` })}`
    : null

  const subcategories = selectedSubcategories
    .map((subcategory) =>
      intl.formatMessage({
        id: `overview.filter.${selectedCategory}.subcategories.${subcategory}`
      })
    )
    .filter(Boolean)
    .join(', ')

  const template = `${category} / ${subcategories} / ${showAll ? 'Todo' : 'Alertas'}`

  return (
    <section className="filters">
      <span>
        {category
          ? template
          : showAll
            ? intl.formatMessage({ id: 'overview.filters.all' })
            : intl.formatMessage({ id: 'overview.filters.alarms' })}
      </span>
      <button onClick={openDialog} aria-label="Open Filters">
        <img src={Icons.filtersIcon.source} alt={Icons.filtersIcon.alt} />
      </button>
    </section>
  )
}
