import timeUtils from '@utils/time'
import Pill from '../Pill/Pill'
import { useIntl } from 'react-intl'
import { useMetrics } from '@hooks/useMetrics'

interface WeeksProps {
  middle: number
  radius: number
  msPerPx: number
  changeCenter: (ms: null) => void
}

export default function WeeksFallback({
  middle,
  msPerPx,
  radius,
  changeCenter
}: WeeksProps) {
  const { center } = useMetrics()
  const week = timeUtils.getWeekByMs(center)
  const intl = useIntl()

  const actual = timeUtils.getMondayAndReset(new Date())

  const actualWeek = week

  const weeks = timeUtils.getWeeksRange(
    middle - radius * 2,
    middle + radius * 2
  )

  return weeks.map((week) => {
    const weekNumber = timeUtils.getWeekByMs(week)
    const isActive = actualWeek === weekNumber

    const translate = (week - middle) / msPerPx

    const isActual = week === actual.getTime()

    return (
      <Pill
        changeCenter={changeCenter}
        ms={week}
        translate={translate}
        isActual={isActual}
        label={intl.formatMessage(
          { id: 'pills.weeks.label' },
          { value: weekNumber }
        )}
        key={week}
        isFuture={week > actual.getTime()}
        active={isActive}
        hasPending={false}
      />
    )
  })
}
