import CustomSelect from '@components/CustomSelect/CustomSelect'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Icons } from '@static/icons'
import { useIntl } from 'react-intl'
import {
  setLang,
  updateIntl,
  useAppDispatch,
  useAppSelector
} from 'universal-scripts'
import locales from '../../../locales'

function LabelComponent({ label }: { label: string }) {
  return (
    <div>
      <span>
        <FormattedMessage
          id="menu.language"
          values={{
            value: <span>{label}</span>
          }}
        />
      </span>
      <img src={Icons.arrowIcon.source} alt={Icons.arrowIcon.alt} />
    </div>
  )
}

function OptionComponent({
  option,
  selected
}: {
  option: { value: string; label: string }
  selected: boolean
}) {
  return <span className={selected ? 'selected' : ''}>{option.label}</span>
}

export default function SelectLanguage() {
  const locale = useAppSelector((s) => s.intl.lang)
  const dispatch = useAppDispatch()

  const intl = useIntl()

  const options: { value: string; label: string }[] = [
    { value: 'es', label: intl.formatMessage({ id: 'menu.language.spanish' }) },
    {
      value: 'ar',
      label: intl.formatMessage({ id: 'menu.language.morocco' })
    },
    {
      value: 'pt',
      label: intl.formatMessage({ id: 'menu.language.portugal' })
    },
    {
      value: 'default',
      label: intl.formatMessage({ id: 'menu.language.default' })
    }
  ]
  const handleChange = (value: string) => {
    const getDefaultLang = (): string => {
      const userLang =
        document.documentElement.lang || window.navigator.language
      return Object.keys(locales).includes(userLang) ? userLang : 'es'
    }

    const lang = value === 'default' ? getDefaultLang() : value
    dispatch(
      updateIntl({
        lang,
        messages: locales[lang]
      })
    )
    document.cookie = `lang=${lang}`
  }

  return (
    <CustomSelect
      LabelComponent={
        <LabelComponent
          label={options.find((option) => option.value === locale).label}
        />
      }
      OptionComponent={OptionComponent}
      onChange={{
        add: handleChange
      }}
      options={options}
      value={locale}
    />
  )
}
