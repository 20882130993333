import { Icons } from '@static/icons'
import { useMetrics } from '@hooks/useMetrics'
import timeUtils from '@utils/time'
import {
  MouseEvent,
  Suspense,
  TouchEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import Weeks from '../Weeks/Weeks'
import Months from '../Months/Months'
import WeeksFallback from '../Weeks/WeeksFallback'
import MonthsFallback from '../Months/MonthsFallback'

import './PillsContainer.css'

const GAP = 140
const SHOWED_ITEMS = 3

const { calendarIcon, weekIcon } = Icons

export default function PillsContainer() {
  const prevWeek = timeUtils.getPreviusWeekDate()

  const sectionRef = useRef<HTMLElement>()
  const [dragging, setDragging] = useState<boolean>(false)

  const { selectedFreq, center } = useMetrics()

  const [[innerCenter, innerRadius], setInnerRange] = useState<
    [number, number]
  >(timeUtils.initializeRangeWeeks(SHOWED_ITEMS, prevWeek))

  const rangeRecord = useRef<{ x: number; center: number }>({
    center: center,
    x: 0
  })

  const isWeekly = selectedFreq === 'weekly'

  const msPerPx = (isWeekly ? 7 : 30) * 600000

  useEffect(() => {
    setInnerRange(([innerCenter]) => {
      const actual = new Date(innerCenter)
      const [newCenter, newRadius] =
        selectedFreq === 'monthly'
          ? timeUtils.initializeRangeMonths(SHOWED_ITEMS, actual)
          : timeUtils.initializeRangeWeeks(SHOWED_ITEMS, actual)
      return [newCenter, newRadius]
    })
  }, [selectedFreq])

  const handleTouchStart = (e: TouchEvent | MouseEvent) => {
    setDragging(true)
    rangeRecord.current = {
      center: innerCenter,
      x: 'touches' in e ? e.targetTouches[0].pageX : e.pageX
    }
  }

  const handleTouchMove = (e: TouchEvent | MouseEvent) => {
    if (!dragging) return
    const x = 'touches' in e ? e.touches[0].pageX : e.pageX
    const walk = rangeRecord.current.x - x

    const msPerPixel = innerRadius / GAP

    const val = walk * msPerPixel

    const prev = rangeRecord.current.center + val

    setInnerRange([prev, innerRadius])
  }

  const handleEnd = () => setDragging(false)

  const handleChange = () => {
    const month = document.querySelector('.first-month')
    const week = document.querySelector('.first-week')
    if (!month || !week) return
    const { top: elementTop } =
      selectedFreq === 'weekly'
        ? month.getBoundingClientRect()
        : week.getBoundingClientRect()
    const { height: headerHeight } = document
      .querySelector('header')
      .getBoundingClientRect()
    const { height: pillsHeight } = document
      .querySelector('.pills')
      .getBoundingClientRect()
    window.scrollTo({
      top: elementTop + window.scrollY - (headerHeight + pillsHeight + 16),
      behavior: 'smooth'
    })
  }

  const changeInnerCenter = (center: number) =>
    setInnerRange((prev) => [center, prev[1]])

  const { pendingMetrics } = useMetrics()

  return (
    <section
      className={`pills ${dragging ? 'dragging' : ''}`}
      ref={sectionRef}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleEnd}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      onMouseUp={handleEnd}
    >
      <div className="switch-freq">
        <button
          className="calendar"
          type="button"
          onClick={handleChange}
          disabled={Object.keys(pendingMetrics).length > 0}
        >
          {isWeekly ? (
            <img src={weekIcon.source} alt={weekIcon.alt} />
          ) : (
            <img src={calendarIcon.source} alt={calendarIcon.alt} />
          )}
        </button>
      </div>
      {isWeekly ? (
        <Suspense
          fallback={
            <WeeksFallback
              changeCenter={changeInnerCenter}
              msPerPx={msPerPx}
              middle={innerCenter}
              radius={innerRadius}
            />
          }
        >
          <Weeks
            msPerPx={msPerPx}
            middle={innerCenter}
            radius={innerRadius}
            changeCenter={changeInnerCenter}
          />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <MonthsFallback
              changeCenter={changeInnerCenter}
              msPerPx={msPerPx}
              middle={innerCenter}
              radius={innerRadius}
            />
          }
        >
          <Months
            msPerPx={msPerPx}
            middle={innerCenter}
            radius={innerRadius}
            changeCenter={changeInnerCenter}
          />
        </Suspense>
      )}
    </section>
  )
}
