import Pill from '../Pill/Pill'
import timeUtils from '@utils/time'
import { useKpisWithPendingData } from '@hooks/useApi'
import { useMetrics } from '@hooks/useMetrics'
import { useAppSelector } from 'universal-scripts'

interface MonthsProps {
  msPerPx: number
  middle: number
  radius: number
  changeCenter: (ms: null) => void
}

export default function Months({
  msPerPx,
  middle,
  radius,
  changeCenter
}: MonthsProps) {
  const { center } = useMetrics()

  const pendingKpis = useKpisWithPendingData()
  const locale = useAppSelector((s) => s.intl.lang)

  const actual = new Date()
  actual.setUTCDate(1)
  actual.setUTCHours(0, 0, 0, 0)

  const selectedWarehouse = useAppSelector((s) => s.warehouse)

  const months = timeUtils.getMonthsRange(
    middle - radius * 2,
    middle + radius * 2
  )

  const monthPendingKpis = pendingKpis.filter(
    (pending) =>
      pending.kpiFrequency === 'monthly' &&
      (selectedWarehouse === pending.warehouse?.toLowerCase() ||
        pending.warehouse === null)
  )

  const pendingMonths = monthPendingKpis
    .map(({ missing_slots }) => missing_slots)
    .flat()

  return months.map((month) => {
    const monthNumber = timeUtils.getMonthByMs(month)

    const isActive =
      new Date(center).getUTCMonth() === new Date(month).getUTCMonth() &&
      new Date(center).getUTCFullYear() === new Date(month).getUTCFullYear()

    const monthName = timeUtils.getMonthName(locale, new Date(month), 'long')

    const translate = (month - middle) / msPerPx

    return (
      <Pill
        ms={month}
        translate={translate}
        label={monthName}
        key={month}
        isFuture={month > actual.getTime()}
        active={isActive}
        hasPending={pendingMonths.includes(
          `${new Date(month).getUTCFullYear()}-M${monthNumber.toString().padStart(2, '0')}`
        )}
        isActual={month === actual.getTime()}
        changeCenter={changeCenter}
      />
    )
  })
}
