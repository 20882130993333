import { useMetrics } from '@hooks/useMetrics'
import Pill from '../Pill/Pill'
import timeUtils from '@utils/time'
import { useAppSelector } from 'universal-scripts'

interface MonthsProps {
  msPerPx: number
  middle: number
  radius: number
  changeCenter: (ms: null) => void
}

export default function MonthsFallback({
  msPerPx,
  middle,
  radius,
  changeCenter
}: MonthsProps) {
  const { center } = useMetrics()
  const month = timeUtils.getMonthByMs(center)
  const actualMonth = month

  const actual = new Date()
  actual.setUTCDate(1)
  actual.setUTCHours(0, 0, 0, 0)

  const locale = useAppSelector((s) => s.intl.lang)

  const months = timeUtils.getMonthsRange(
    middle - radius * 2,
    middle + radius * 2
  )

  return months.map((month, index) => {
    const monthNumber = timeUtils.getMonthByMs(month)

    const isActive = actualMonth === monthNumber

    const monthName = timeUtils.getMonthName(locale, new Date(month), 'long')

    const translate = (month - middle) / msPerPx

    return (
      <Pill
        changeCenter={changeCenter}
        ms={month}
        translate={translate}
        label={monthName}
        key={index}
        isFuture={month > actual.getTime()}
        active={isActive}
        hasPending={false}
        isActual={month === actual.getTime()}
      />
    )
  })
}
