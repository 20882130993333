// import { useState } from 'react'
import { useFilters, useFiltersData } from '@hooks/useFilters'
import { categoriesData } from 'src/data/categories'
import { FormattedMessage } from 'react-intl'
import Toggle from '../Toggle/Toggle'

import './FiltersContainer.css'

interface FiltersContainerProps {
  open: boolean
  closeDialog: () => void
}

export default function FiltersContainer({
  open,
  closeDialog
}: FiltersContainerProps) {
  const {
    changeSelectedCategory: changeSelectedCategoryDef,
    changeSelectedSubcategories: changeSelectedSubcategoriesDef,
    toggleShow: toggleShowDef,
    showAll: showAllDef,
    selectedCategory: selectedCategoryDef,
    selectedSubcategories: selectedSubcategoriesDef
  } = useFilters()

  const {
    changeSelectedCategory,
    handleSelectedSubcategory,
    changeSelectedSubcategories,
    toggleShow,
    showAll,
    selectedCategory,
    selectedSubcategories
  } = useFiltersData({
    category: selectedCategoryDef,
    subcategories: selectedSubcategoriesDef,
    showAllInitial: showAllDef
  })

  const handleSave = () => {
    changeSelectedCategoryDef(selectedCategory)
    changeSelectedSubcategoriesDef(selectedSubcategories)
    if (showAll !== showAllDef) toggleShowDef()
    closeDialog()
  }

  const subcategories =
    categoriesData.find(({ name }) => selectedCategory === name)
      ?.subcategories ?? []

  const handleChangeCategory = (name: string) => {
    changeSelectedSubcategories([])
    changeSelectedCategory(name)
  }

  return (
    <dialog open={open} id="filters-dialog">
      <div className="container">
        <header>
          <button className="apply" onClick={handleSave}>
            <FormattedMessage id="overview.filters.apply" />
          </button>
          <div>
            <button
              className="close"
              onClick={closeDialog}
              aria-label="Close Filters"
            ></button>
          </div>
        </header>
        <section>
          <h2>
            <FormattedMessage id="overview.filters.title" />
          </h2>
          <div className="categories">
            <button
              onClick={() => handleChangeCategory(null)}
              className={selectedCategory === null ? 'selected' : ''}
            >
              <FormattedMessage id="overview.filter.general.name" />
            </button>
            {categoriesData.map(({ name }) => (
              <button
                key={name}
                onClick={() => handleChangeCategory(name)}
                className={selectedCategory === name ? 'selected' : ''}
              >
                <FormattedMessage id={`overview.filter.${name}.name`} />
              </button>
            ))}
          </div>
          <div className="subcategories">
            <button
              onClick={() => changeSelectedSubcategories([])}
              className={selectedSubcategories.length === 0 ? 'selected' : ''}
            >
              <FormattedMessage id="overview.filters.subcategories.all" />
            </button>
            {subcategories.map((name) => (
              <button
                key={name}
                onClick={() => {
                  handleSelectedSubcategory(name)
                }}
                className={
                  selectedSubcategories.includes(name) ? 'selected' : ''
                }
              >
                <FormattedMessage
                  id={`overview.filter.${selectedCategory}.subcategories.${name}`}
                />
              </button>
            ))}
          </div>
        </section>
        <footer>
          <span className={showAll ? 'bold' : ''}>
            <FormattedMessage id="overview.filters.all" />
          </span>
          <Toggle value={!showAll} handleChange={toggleShow} />
          <span className={!showAll ? 'bold' : ''}>
            <FormattedMessage id="overview.filters.alarms" />
          </span>
        </footer>
      </div>
    </dialog>
  )
}
