import { Factory } from 'src/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: Factory = 'fundilusa' as Factory

const warehouseSlice = createSlice({
  initialState,
  name: 'warehouse',
  reducers: {
    changeWarehouse(state) {
      return state === 'fundimaroc' ? 'fundilusa' : 'fundimaroc'
    }
  }
})

export const { changeWarehouse } = warehouseSlice.actions
export default warehouseSlice.reducer
