import chartsUtils, { MergedChartData } from '@utils/charts'
import { useId } from 'react'
import { Kpi } from 'src/server/types'
import { Frequency } from 'src/types'

const ROWS = 25
const INITIAL_X_GAP = 20

const regex = /^(\w+),\s*(\w+)$/

export default function BarChart({
  data,
  prev,
  maxTarget,
  frequency
}: {
  data: MergedChartData[]
  prev: Pick<Kpi, 'value' | 'targetValue'> | null
  maxTarget: number
  frequency: Frequency
}) {
  const label = frequency === 'monthly' ? 'M' : 'W'

  const id = useId()

  const gapY = 3 // px
  const gapX = 14 // px

  const height = 3 // px
  const width = 24 // px

  const generateRows = (
    x: number,
    value: number,
    maxValue: number,
    color: string
  ) => {
    const row = []
    const rowsCompleted = Math.round(
      chartsUtils.converToSections(value, maxValue, ROWS)
    )

    for (let i = 0; i < ROWS; i++) {
      row.push(
        <rect
          x={x}
          y={i * (gapY + height)}
          width={width}
          height={height}
          key={i}
          fill={i <= ROWS - rowsCompleted ? '#101541' : color}
          className="graph-item"
        />
      )
    }

    return row
  }

  const generateLinePoints = (data: Pick<Kpi, 'value' | 'targetValue'>[]) => {
    return data.map(({ targetValue }, i) => {
      const test = Math.round(
        chartsUtils.converToSections(targetValue || 0, maxTarget, ROWS)
      )
      const x = i * (gapX + width) + width / 2
      const y = (ROWS - test) * (gapY + height)
      return `${x + INITIAL_X_GAP},${y}`
    })
  }

  const generateLine = (clipPath: boolean, line: boolean) => {
    if (data.length === 0) return ''
    const previous = `0,${
      (ROWS -
        Math.round(
          chartsUtils.converToSections(prev?.targetValue || 0, maxTarget, ROWS)
        )) *
      (gapY + height)
    }`
    const points = generateLinePoints(data)

    const last = points.at(-1)
    const match = last.match(regex)
    const [, x] = match
    const reduce = clipPath ? 2 : 0

    const [first] = points
    const firstMatch = first.match(regex)
    const [, , y] = firstMatch

    const closing = `${x + width + gapX},${ROWS * (gapY + height) - reduce}`

    const closingPath = `${x + width + gapX},${ROWS * (gapY + height) - reduce} 0,${
      ROWS * (gapY + height) - reduce
    }`

    return `${prev ? previous : `6,${y}`} ${points.join(' ')} ${line ? closing : closingPath}`
  }

  return (
    <svg
      height="100%"
      width={`${data.length * (gapX + width) + INITIAL_X_GAP}px`}
      className="graph-item"
    >
      <defs>
        <clipPath id={`chart-clip-${id}`}>
          <polygon points={generateLine(true, false)} />
        </clipPath>
      </defs>
      {/* <polygon
        points={generateLine(true, false)}
        fill="hsl(103, 100%, 4%)"
        className="graph-item"
      /> */}

      {data.map(({ value, time, year }, i) => (
        <g key={i} className="graph-item">
          {generateRows(
            i * (gapX + width) + INITIAL_X_GAP,
            value,
            maxTarget,
            '#28A9FF'
          )}
          <text
            y={ROWS * (gapY + height) + 12}
            x={i * (gapX + width) + INITIAL_X_GAP}
            fill="#FFF"
            fontSize="12px"
            className="graph-item"
          >
            {label === 'W'
              ? `${label}${time}`
              : chartsUtils.getMonthLabels(time, year)}
          </text>
        </g>
      ))}

      <g clipPath={`url(#chart-clip-${id})`}>
        {data.map(({ value }, i) => (
          <g key={i} className="graph-item">
            {generateRows(
              i * (gapX + width) + INITIAL_X_GAP,
              value,
              maxTarget,
              '#EC6269'
            )}
          </g>
        ))}
      </g>

      <polyline
        points={generateLine(false, true)}
        fill="none"
        stroke="#6FFF4A"
        strokeWidth="2"
        className="graph-item"
      />
    </svg>
  )
}
