import { FormattedMessage } from '@components/Intl/FormattedMessage'
import AnimatedBg from '@components/AnimatedBg/AnimatedBg'

import logo from '@static/logo/fundilusa-logo.svg'
import './Login.css'

const tenantID = process.env.TENANT_ID
const clientID = process.env.CLIENT_ID
const appUrl = process.env.APP_URL || 'http://localhost:3000'

export default function Login() {
  const oauth =
    `https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize` +
    `?client_id=${clientID}` +
    `&scope=${encodeURIComponent('openid profile email https://graph.microsoft.com/user.read')}` +
    '&response_type=code' +
    `&redirect_uri=${encodeURIComponent(`${appUrl}/oauth`)}` +
    '&response_mode=query'

  return (
    <main id="login">
      <AnimatedBg />
      <div className="foreground">
        <img srcSet={logo} alt="Fundilusa logo" className="logo" />
        <h1>
          <FormattedMessage id="login.title" />
        </h1>
        <a href={oauth}>
          <FormattedMessage id="login" />
        </a>
      </div>
    </main>
  )
}
