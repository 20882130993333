import { Component, ErrorInfo, FC, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
  errorComponent: FC<{ error: Error | null }>
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('ErrorBoundary atrapó un error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      console.log(this.state.error)
      return <this.props.errorComponent error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
