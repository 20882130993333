import {
  useFetch,
  useFetchCb,
  useFetchUncachedCb
} from '@gluedigital/ruse-fetch-extras'
import { Overview, Kpi, Pending } from 'src/server/types'

const baseApiUrl = process.env.API_URL || 'http://localhost:3000/api'

export function useKpisWithMetadata(): Overview {
  const kpis = useFetch<Overview>(`${baseApiUrl}/kpi/overview`, {
    credentials: 'include'
  })

  return kpis
}

export function useKpisActivity(center: number): Overview {
  const kpis = useFetch<Overview>(`${baseApiUrl}/kpi/activity`, {
    credentials: 'include',
    params: {
      center
    }
  })

  return kpis
}

export function useRefreshKpisActivity(): () => Promise<void> {
  const refresh = useFetchCb<void>()

  return () => refresh(`${baseApiUrl}/kpi/activity`, { credentials: 'include' })
}

export function useKpisWithPendingData(): Pending {
  const pendingKpis = useFetch<Pending>(`${baseApiUrl}/kpi/pending`, {
    credentials: 'include'
  })

  return pendingKpis
}

export function useRefreshPendingKpis(): () => Promise<void> {
  const refresh = useFetchCb<void>()

  return () =>
    refresh(`${baseApiUrl}/kpi/pending`, {
      credentials: 'include'
    })
}

export function useUpdateKpis(): (body: Omit<Kpi, 'id'>[]) => Promise<Kpi[]> {
  const fetch = useFetchUncachedCb()

  return (body) =>
    fetch(`${baseApiUrl}/kpi`, {
      method: 'POST',
      body,
      credentials: 'include'
    })
}
