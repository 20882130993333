import { Routes, Route } from 'react-router-dom'
import Dashboard from '@routes/Dashboard/Dashboard'
import Layout from '@components/Layout/Layout'
import DashboardLayout from '@components/Layout/DashboardLayout'
import Meta from '@components/Metas/Meta'
import Visualizer from './Overview/Overview'
import Login from './Login/Login'
import OAuth from './OAuth/OAuth'
import { AuthRoutes, NotAuthRoutes } from '@components/Layout/AuthRoutes'
import { StrictMode } from 'react'

const App = () => {
  return (
    <>
      <Meta />
      <Routes>
        <Route element={<NotAuthRoutes />}>
          <Route path="/oauth" element={<OAuth />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthRoutes />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route element={<Dashboard />} index />
          </Route>
        </Route>
        <Route element={<AuthRoutes />}>
          <Route element={<Layout />}>
            <Route path="/overview" element={<Visualizer />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
