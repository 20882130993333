import { FormattedMessage } from '@components/Intl/FormattedMessage'
import PillsContainer from '@components/Pill/PillsContainer/PillsContainer'
import MetricsContainer from '@components/Metrics/MetricsContainer/MetricsContainer'
import { Suspense } from 'react'
import MetricsContainerFallback from '@components/Metrics/MetricsContainer/MetricsContainerFallback'

import './Dashboard.css'

export default function Dashboard() {
  return (
    <main id="dashboard">
      <div className="container">
        <h1>
          <FormattedMessage id="dashboard.title" />
        </h1>
        <PillsContainer />
        <Suspense fallback={<MetricsContainerFallback />}>
          <MetricsContainer />
        </Suspense>
      </div>
    </main>
  )
}
