import { useCallback, useEffect, useRef, useState } from 'react'
import KpiChartRow from '../KpiChartRow/KpiChartRow'
import { useKpisWithMetadata } from '@hooks/useApi'
import { useKpiWithData } from '@hooks/useKpi'
import metricsUtils from '@utils/metrics'
import { useAppSelector } from 'universal-scripts'

export default function KpiChartsContainer() {
  const kpis = useKpisWithMetadata()

  const [pinnedKpis, setPinnedKpis] = useState<number[]>([])

  useEffect(() => {
    const pinnedIds = metricsUtils.recoverPinnedKpis()
    const populatedKpis = kpis
      .filter((kpi) => pinnedIds.includes(kpi.id))
      .map((kpi) => kpi.id)
    setPinnedKpis(populatedKpis)
  }, [kpis])

  const updatePinnedKpi = useCallback((id: number) => {
    setPinnedKpis((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    )
  }, [])

  const selectedWarehouse = useAppSelector((s) => s.warehouse)

  const divRef = useRef<HTMLDivElement>()

  const kpisWithDataFundilusa = useKpiWithData(kpis, 'fundilusa')
  const kpisWithDataFundimaroc = useKpiWithData(kpis, 'fundimaroc')

  const showElements =
    selectedWarehouse === 'fundilusa'
      ? kpisWithDataFundilusa
      : kpisWithDataFundimaroc

  const orderedElements = metricsUtils.sortByPinned(
    structuredClone(showElements),
    pinnedKpis
  )

  return (
    <div ref={divRef}>
      {orderedElements.map(
        ({ actual, title, data, kpiFrequency, target, id }) => {
          return (
            <KpiChartRow
              id={id}
              key={id}
              name={title}
              actual={{
                target: actual ? (actual.targetValue ?? target) : target,
                value: actual ? actual.value : 0
              }}
              data={data}
              frequency={kpiFrequency}
              allPinned={pinnedKpis}
              updatePinnedKpi={updatePinnedKpi}
            />
          )
        }
      )}
    </div>
  )
}
