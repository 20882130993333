import { ReactNode } from 'react'
import { Icons } from '@static/icons'
import Common from '../Menu/Common'
import { CountryFlags } from '@static/icons/countries'
import { Factory } from 'src/types'
import { useIntl } from 'react-intl'
import Menu from '@components/Menu/Menu'
import useHeader from '@hooks/useHeader'
import { useMetrics } from '@hooks/useMetrics'

import './Header.css'
import { useAppDispatch, useAppSelector } from 'universal-scripts'
import { changeWarehouse } from 'src/store/slices/warehouse'

const { alt: houseAlt, source: houseSource } = Icons.houseIcon
const { alt: crossAlt, source: crossSource } = Icons.crossIcon

interface HeaderMetricsProps {
  openMenu: boolean
  handleMenu: (newValue: boolean) => void
  handleResetMetrics: () => void
  element: ReactNode
  isPending: boolean
}

const buildClassName = (openMenu: boolean, isAtTop: boolean) => {
  const res = openMenu ? ['open'] : ['close']
  if (isAtTop) res.push('isAtTop')
  return res.join(' ')
}

export function UserIcon() {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { pendingMetrics } = useMetrics()
  const selectedWarehouse = useAppSelector((s) => s.warehouse)

  const handleChangeWarehouse = () => {
    if (Object.keys(pendingMetrics).length === 0) dispatch(changeWarehouse())
  }

  return (
    <img
      src={CountryFlags[selectedWarehouse]}
      alt={`${selectedWarehouse} flag`}
      role="button"
      tabIndex={0}
      aria-label={intl.formatMessage(
        { id: 'header.flag.aria-label' },
        {
          value: intl.formatMessage({ id: `header.flag.${selectedWarehouse}` })
        }
      )}
      className={`user-logo ${selectedWarehouse === 'fundilusa' ? '' : 'morocco'}`}
      onClick={handleChangeWarehouse}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') handleChangeWarehouse()
      }}
    />
  )
}

export function HomeButton({
  handleClick,
  openMenu
}: Pick<HeaderMetricsProps, 'openMenu'> & { handleClick: () => void }) {
  return (
    <button type="button" className="home" onClick={handleClick}>
      <img
        src={openMenu ? crossSource : houseSource}
        alt={openMenu ? crossAlt : houseAlt}
      />
    </button>
  )
}

export function HeaderMetrics({
  element,
  handleMenu,
  openMenu,
  handleResetMetrics,
  isPending
}: HeaderMetricsProps) {
  const commonContent = <>{element}</>

  const isAtTop = useHeader()

  const handleOpenMenu = () => handleMenu(!openMenu)

  return (
    <>
      <header
        id="main-header"
        className={`metrics ${buildClassName(openMenu, isAtTop)}`}
      >
        <div className="container">
          <UserIcon />

          {!isPending ? (
            <>
              {commonContent}
              <HomeButton handleClick={handleOpenMenu} openMenu={openMenu} />
            </>
          ) : (
            <div className="metrics-container">
              {commonContent}
              <HomeButton handleClick={handleResetMetrics} openMenu={true} />
            </div>
          )}
        </div>
      </header>
      {openMenu ? (
        <div className="container">
          <Menu open={openMenu} />
        </div>
      ) : null}
    </>
  )
}

export default function Header({
  handleMenu,
  openMenu
}: Pick<HeaderMetricsProps, 'handleMenu' | 'openMenu'>) {
  const isAtTop = useHeader()

  const handleOpenMenu = () => handleMenu(!openMenu)

  return (
    <>
      <header id="main-header" className={buildClassName(openMenu, isAtTop)}>
        <div className="container">
          <UserIcon />
          <Common />
          <HomeButton handleClick={handleOpenMenu} openMenu={openMenu} />
        </div>
      </header>
      {openMenu ? (
        <div className="container">
          <Menu open={openMenu} />
        </div>
      ) : null}
    </>
  )
}
