import type { CompleteKpi, Factory, Frequency, KpiWithData } from 'src/types'
import type { Overview, Kpi } from 'src/server/types'
import timeUtils from './time'

function getMetricsWithValues(
  metrics: CompleteKpi[],
  center: number
): Record<number, Omit<Kpi, 'id'>> {
  const slots = getSlotsByDate(center)
  return metrics.reduce<Record<number, Omit<Kpi, 'id'>>>(
    (historyRecord, metric) => {
      const currentHistory = metric.history.find(
        (history) => history.slot === slots.month || history.slot === slots.week
      )

      historyRecord[metric.id] = currentHistory || {
        metadataId: metric.id,
        slot: metric.kpiFrequency === 'monthly' ? slots.month : slots.week,
        value: null,
        targetValue: metric.target
      }

      return historyRecord
    },
    {}
  )
}

function orderMetrics<
  T extends { kpiFrequency: Frequency; warehouse: string | null }
>(metrics: T[], order: Frequency): T[] {
  const sortedMetrics = structuredClone(metrics).sort((metricA, metricB) => {
    // First, sort by kpiFrequency (prioritizing those matching 'order')
    if (metricA.kpiFrequency === order && metricB.kpiFrequency !== order)
      return -1
    if (metricA.kpiFrequency !== order && metricB.kpiFrequency === order)
      return 1

    // If both have the same kpiFrequency, sort by warehouse (prioritizing null values)
    if (metricA.warehouse === null && metricB.warehouse !== null) return -1
    if (metricA.warehouse !== null && metricB.warehouse === null) return 1

    // If both have the same kpiFrequency and both have or don't have a warehouse, maintain the current order
    return 0
  })

  return sortedMetrics
}

function savePinned(newItemId: number) {
  const prevData =
    JSON.parse(window.localStorage.getItem('pinnedMetrics')) ?? []
  const newData = [...prevData, newItemId]
  window.localStorage.setItem('pinnedMetrics', JSON.stringify(newData))
}

function removePinned(removeItemId: number) {
  const prevData =
    (JSON.parse(window.localStorage.getItem('pinnedMetrics')) as number[]) ?? []
  const newData = prevData.filter((data) => data !== removeItemId)
  window.localStorage.setItem('pinnedMetrics', JSON.stringify(newData))
}

function recoverPinnedKpis(): number[] {
  const data = JSON.parse(window.localStorage.getItem('pinnedMetrics')) ?? []
  return data
}

function filterByWarehouse(
  metrics: Overview,
  selectedWarehouse: Factory
): CompleteKpi[] {
  return metrics.filter((kpi) =>
    typeof kpi.warehouse === 'string'
      ? selectedWarehouse === kpi.warehouse.toLowerCase()
      : true
  )
}

function sortByPinned(
  data: KpiWithData[],
  pinnedItems: number[]
): KpiWithData[] {
  data.sort((a, b) => {
    const isInPinnedA = pinnedItems.includes(a.id)
    const isInPinnedB = pinnedItems.includes(b.id)

    if (isInPinnedA && !isInPinnedB) return -1
    if (!isInPinnedA && isInPinnedB) return 1
    return 0
  })
  return data
}

function getSlotsByDate(center: number): { month: string; week: string } {
  const date = new Date(center)
  const week = timeUtils.getWeekByMs(center).toString().padStart(2, '0')
  const month = timeUtils.getMonthByMs(center).toString().padStart(2, '0')
  const currentSlotMonth = `${date.getUTCFullYear()}-M${month}`
  const currentSlotWeek = `${date.getUTCFullYear()}-W${week}`
  return { month: currentSlotMonth, week: currentSlotWeek }
}

const metricsUtils = {
  getMetricsWithValues,
  orderMetrics,
  savePinned,
  removePinned,
  filterByWarehouse,
  recoverPinnedKpis,
  sortByPinned,
  getSlotsByDate
}

export default metricsUtils
